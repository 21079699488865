'use client';

import './globals.css';

import { ConfigProvider } from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import { Inter } from 'next/font/google';

import { theme } from '@/common/theme';
import NotificationProvider from '@/providers/NotificationProvider';
import { configureClientAmplify } from '@/utils/amplify/amplifyClientUtils';

import StyledComponentsRegistry from '../lib/AntdRegistry';

const inter = Inter({ subsets: ['latin'] });

configureClientAmplify();

const RootLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <html lang='ja'>
      <body className={inter.className}>
        <ConfigProvider theme={theme} locale={ja_JP}>
          <NotificationProvider>
            <StyledComponentsRegistry>{children}</StyledComponentsRegistry>
          </NotificationProvider>
        </ConfigProvider>
      </body>
    </html>
  );
};

export default RootLayout;
